'use client';

import styled from 'styled-components';
import { H3 } from '@/lib/StyledComponents/Typography';
import { Card } from '../shared/Card/Card';
import { type ContactCardBlockData } from '@/schema/cms/blocks/ContactCardBlockSchema';
import { mq } from '@/lib/StyledComponents/mq';
import { Image as CmsImage } from 'react-datocms';

type ContactCardProps = Pick<
  ContactCardBlockData,
  'name' | 'image' | 'jobTitle' | 'phoneNumber' | 'emailAddress'
>;

const StyledCard = styled(Card)`
  position: relative;
  text-align: center;
  border-radius: 10px;
  margin-top: 7rem;
  padding: 7.5rem 1.875rem 2.5rem;
  min-width: 29rem;

  ${mq(null, 'tablet')} {
    width: 90%;
    min-width: auto;
  }
`;

const ProfileImageContainer = styled.div`
  position: absolute;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 100px;
  overflow: hidden;
`;

const JobTitle = styled.p`
  text-transform: uppercase;
  font-weight: 900;
  opacity: 0.6;

  margin: 0;
`;

const Name = styled(H3)`
  font-size: 1.5rem;
  margin: 0;
`;

const Link = styled.a`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.main.info};
  font-size: clamp(0.875rem, 2vw, 1rem);
`;

export function ContactCard({
  name,
  image,
  jobTitle,
  phoneNumber,
  emailAddress,
}: ContactCardProps) {
  return (
    <StyledCard>
      <ProfileImageContainer>
        {image?.responsiveImage && (
          <CmsImage
            data={image?.responsiveImage}
            objectFit="cover"
            style={{ position: 'static', height: '100%' }}
          />
        )}
      </ProfileImageContainer>
      <JobTitle>{jobTitle}</JobTitle>
      <Name>{name}</Name>
      <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
      <Link href={`mailto:${emailAddress}`}>{emailAddress}</Link>
    </StyledCard>
  );
}
