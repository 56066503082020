import { type PartnerWidgetCardsBlockData } from '@/schema/cms/blocks/PartnerWidgetCardsBlockSchema';
import { useTranslation } from 'react-i18next';
import { mq } from '@/lib/StyledComponents/mq';
import styled from 'styled-components';
import { Card } from '../shared/Card/Card';
import Image from 'next/image';
import { H2 } from '@/lib/StyledComponents/Typography';
import { Button } from '../shared/Button';
import { Input } from '../shared/Input';
import { BodyText } from '@/lib/StyledComponents/Typography';
import { usePartnerWidget } from './usePartnerWidget';

const StyledCard = styled(Card)`
  padding: 1.875rem;

  ${mq('tablet')} {
    padding: 3rem;
    flex-basis: calc(33.33333% - 1rem);
    flex-grow: 0;
  }
`;

const StyledBodyText = styled(BodyText)`
  margin-bottom: 0.5rem;
`;

const ActionForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;
  width: 100%;
  flex: 1;
`;

const Title = styled(H2)`
  margin-bottom: 0rem;

  ${mq('laptop')} {
    font-size: 2.25rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const ErrorText = styled(BodyText)`
  color: ${({ theme }) => theme.colors.main.error};
  text-align: center;
`;

type PartnerWidgetCardProps = Pick<
  PartnerWidgetCardsBlockData,
  'logo' | 'title' | 'description' | 'apiLink'
>;

export const PartnerWidgetCard = ({
  logo,
  title,
  description,
  apiLink,
}: PartnerWidgetCardProps) => {
  const { t } = useTranslation();

  const { inputName, error, handleOnBlur, handleOnChange, onSubmit } =
    usePartnerWidget(apiLink, 'input');

  return (
    <StyledCard>
      <Image src={logo.url} alt={logo.alt} width={180} height={140} />
      <Title $textColor="dark" $align="center">
        {title}
      </Title>
      <StyledBodyText $textColor="dark" $align="center">
        {description}
      </StyledBodyText>
      {apiLink && (
        <ActionForm target="_blank" onSubmit={onSubmit}>
          <InputContainer>
            <Input
              name={inputName}
              $borderColor="gray"
              $textAlign="center"
              placeholder={t('Enter your license plate')}
              $fullWidth
              $uppercase
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {error && <ErrorText>{error}</ErrorText>}
          </InputContainer>
          <Button
            $fullWidth
            $backgroundColor="tertiary"
            $textColor="light"
            type="submit"
          >
            {t('Submit')}
          </Button>
        </ActionForm>
      )}
    </StyledCard>
  );
};
