'use client';

import { H4 } from '@/lib/StyledComponents/Typography';
import { mq } from '@/lib/StyledComponents/mq';
import { type ContactCardBlockData } from '@/schema/cms/blocks/ContactCardBlockSchema';
import styled from 'styled-components';
import { QuoteCard } from './CmsBlocks/QuoteCard';
import { type PartnerWidgetCardsBlockData } from '@/schema/cms/blocks/PartnerWidgetCardsBlockSchema';
import { type QuoteCardBlockData } from '@/schema/cms/blocks/QuoteCardBlockSchema';
import { ContactCard } from './CmsBlocks/ContactCard';
import { PartnerWidgetCard } from './CmsBlocks/PartnerWidgetCard';
import type { SructuredTextCardBlockData } from '@/schema/cms/blocks/StructuredTextCardBlockSchema';
import StructuredText from './shared/StructuredText';
import { Card } from './shared/Card/Card';
import BackgroundImage from './CmsBlocks/BackgroundImage';
import type {
  ResponsiveImageType,
  StructuredTextGraphQlResponse,
} from 'react-datocms';
import type { PartnersCardBlockData } from '@/schema/cms/blocks/PartnersCardBlock.schema';
import type { ArticleCardModel } from '@/schema/cms/models/ArticlePageModel.schema';
import type { ReactElement } from 'react';
import { ArticleCard } from '../(pages)/articles/_components/ArticleCard';

type CardSectionWrapperProps = {
  $isBackgroundImage: boolean;
  $noPaddingTop: boolean;
};

const CardsSectionWrapper = styled.section<CardSectionWrapperProps>`
  position: relative;

  ${({ theme, $isBackgroundImage }) =>
    !$isBackgroundImage &&
    `background-color: ${theme.colors.background.dark1};
    `}
  ${({ $noPaddingTop }) =>
    !$noPaddingTop &&
    `padding-top: 3.75rem;
      `}
`;

type CardsSectionWrapperProps = {
  offset?: number;
};

const CardsWrapper = styled.div<CardsSectionWrapperProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.neutral};

  padding: 0 1.25rem;
  padding-bottom: 3.75rem;

  > :before {
    height: ${({ offset }) => `${offset ?? 14.75}rem`};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.dark1};
  }
`;

const CardsInner = styled.div<{
  $justifyContent: React.CSSProperties['justifyContent'];
}>`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  /* > * {
    flex: 1;
  } */

  ${mq('laptop')} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${({ $justifyContent }) => $justifyContent};
    max-width: 90rem;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.dark1};
  padding-bottom: 1.875rem;

  h4 {
    letter-spacing: 0.08em;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.main.primary};
    margin: 0;

    ${mq('tablet')} {
      font-size: 1rem;
    }
  }
`;

const StyledCard = styled(Card)<{
  $textAlign: React.CSSProperties['textAlign'];
}>`
  padding: 2rem 3rem;
  text-align: ${({ $textAlign = 'center' }) => $textAlign};

  ${mq('laptop')} {
    max-width: 48rem;
  }
`;

type CardsSectionProps = {
  cards: (
    | ContactCardBlockData
    | QuoteCardBlockData
    | PartnersCardBlockData
    | PartnerWidgetCardsBlockData
    | SructuredTextCardBlockData
    | ArticleCardModel
  )[];
  title?: string | null;
  offset?: number;
  backgroundImage?: ResponsiveImageType;
  justifyContent?: React.CSSProperties['justifyContent'];
  navComponent?: ReactElement;
  noPaddingTop?: boolean;
};

export const CardsSection = ({
  title,
  cards,
  offset,
  backgroundImage,
  justifyContent = 'center',
  navComponent: NavComponent,
  noPaddingTop,
}: CardsSectionProps) => {
  if (cards.length === 0 && !title) return null;

  const isBackgroundImage = Boolean(backgroundImage);

  return (
    <CardsSectionWrapper
      $isBackgroundImage={isBackgroundImage}
      $noPaddingTop={noPaddingTop ?? isBackgroundImage}
    >
      {title && (
        <Text>
          <H4 $textColor="primary">{title}</H4>
        </Text>
      )}

      {backgroundImage && <BackgroundImage responsiveImage={backgroundImage} />}

      {NavComponent && NavComponent}

      {cards.length > 0 && (
        <CardsWrapper offset={offset}>
          <CardsInner $justifyContent={justifyContent}>
            {cards.map(card => {
              switch (card.__typename) {
                case 'PartnerWidgetCardRecord':
                  return (
                    <PartnerWidgetCard
                      key={card.id}
                      logo={card.logo}
                      title={card.title}
                      description={card.description}
                      apiLink={card.apiLink}
                    />
                  );
                case 'ContactCardRecord':
                  return (
                    <ContactCard
                      key={card.id}
                      image={card.image}
                      jobTitle={card.jobTitle}
                      name={card.name}
                      phoneNumber={card.phoneNumber}
                      emailAddress={card.emailAddress}
                    />
                  );
                case 'QuoteCardRecord':
                  return (
                    <QuoteCard
                      key={card.id}
                      image={card.image}
                      quote={card.quote}
                      quoteBy={card.quoteBy}
                    />
                  );
                case 'StructuredTextCardRecord':
                  return (
                    <StyledCard key={card.id} $textAlign={card.textAlign}>
                      <StructuredText
                        data={card.body as StructuredTextGraphQlResponse}
                      />
                    </StyledCard>
                  );
                case 'ArticlePageRecord':
                  return <ArticleCard key={card.id} articleData={card} />;
              }
            })}
          </CardsInner>
        </CardsWrapper>
      )}
    </CardsSectionWrapper>
  );
};
