'use client';

import styled from 'styled-components';
import { H4 } from '@/lib/StyledComponents/Typography';
import { Card } from '../shared/Card/Card';
import { type QuoteCardBlockData } from '@/schema/cms/blocks/QuoteCardBlockSchema';
import { mq } from '@/lib/StyledComponents/mq';
import { Image as CmsImage } from 'react-datocms';

const StyledCard = styled(Card)`
  position: relative;
  text-align: center;
  border-radius: 10px;
  margin-top: 7rem;
  padding: 7.5rem 1.875rem 2.5rem;
  max-width: 29rem;

  ${mq(null, 'laptop')} {
    width: 90%;
    max-width: none;
  }
`;

const ProfileImageContainer = styled.div`
  position: absolute;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 100px;
  overflow: hidden;
`;

const Quote = styled.div`
  font-weight: 600;
  font-size: clamp(1.25rem, 2vw, 1.5rem);
`;

const QuoteBy = styled(H4)`
  text-transform: uppercase;
  opacity: 0.6;
  margin: 0;
`;

type QuoteCardProps = Pick<QuoteCardBlockData, 'quote' | 'quoteBy' | 'image'>;

export function QuoteCard({ image, quote, quoteBy }: QuoteCardProps) {
  return (
    <StyledCard>
      <ProfileImageContainer>
        {image?.responsiveImage && (
          <CmsImage
            data={image?.responsiveImage}
            objectFit="cover"
            style={{ position: 'static', height: '100%' }}
          />
        )}
      </ProfileImageContainer>

      <Quote>{quote}</Quote>

      <QuoteBy>{quoteBy}</QuoteBy>
    </StyledCard>
  );
}
