'use client';

import { H3, BodyText } from '@/lib/StyledComponents/Typography';
import styled from 'styled-components';
import { mq } from '@/lib/StyledComponents/mq';
import { Image as CmsImage } from 'react-datocms';
import type { ArticleCardModel } from '@/schema/cms/models/ArticlePageModel.schema';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Card } from '@/app/(components)/shared/Card/Card';
import { ArticleCategoryChip } from './ArticleCategoryChip';

const StyledCard = styled(Card)`
  padding: 0;

  ${mq('laptop')} {
    max-width: 460px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 256px;

  ${mq('laptop')} {
    height: 320px;
  }
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

const CategoryChips = styled.div`
  display: flex;
  gap: 0.375rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Title = styled(H3)`
  margin-bottom: 0.875rem;

  ${mq('laptop')} {
    font-size: 1.5rem;
  }

  ${mq('laptopXL')} {
    font-size: 2rem;
  }
`;

const Description = styled(BodyText)`
  margin-bottom: 0.875rem;

  ${mq('tablet')} {
    font-size: 1rem;
  }
`;

const ReadMore = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-transform: uppercase;

  ${mq('laptop')} {
    font-size: 1rem;
  }
`;

type Props = {
  articleData: ArticleCardModel;
};

export const ArticleCard = ({ articleData }: Props) => {
  const { t } = useTranslation();
  const { heroImage, title, category, slug, cardDescription } = articleData;

  return (
    <Link href={`/articles/${slug}`} data-testid="article-card-link">
      <StyledCard>
        <ImageContainer>
          <CmsImage
            data={heroImage?.responsiveImage}
            objectFit="cover"
            style={{
              height: '100%',
              borderRadius: '0.625rem 0.625rem 0 0',
            }}
            placeholderStyle={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          />
        </ImageContainer>
        <ContentContainer>
          <CategoryChips>
            {category.map(({ name, color }, index) => (
              <ArticleCategoryChip
                key={`${slug}-${index}`}
                label={name}
                color={color}
              />
            ))}
          </CategoryChips>
          <Title $textColor="dark">{title}</Title>
          <Description>{cardDescription}</Description>
          <ReadMore>{t('Read more')}</ReadMore>
        </ContentContainer>
      </StyledCard>
    </Link>
  );
};
