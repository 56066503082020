import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const usePartnerWidget = (apiLink: string | null, inputName: string) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const apiParam = useMemo(() => {
    if (!apiLink) {
      return null;
    }

    const url = new URL(apiLink);
    const inputParam = Array.from(url.searchParams.entries()).find(
      ([, value]) => value === '{INPUT}'
    );
    return inputParam;
  }, [apiLink]);

  const validateInput = useCallback(
    (inputValue: string) => {
      const input = inputValue;

      const parsedInput = input?.replaceAll(' ', '').toUpperCase();

      if (!parsedInput || parsedInput.length < 6 || parsedInput.length > 7) {
        setError(t('Invalid license plate (6 characters)'));
        return false;
      }

      setError(null);

      return parsedInput;
    },
    [t]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      validateInput(e.target.value);
    },
    [validateInput]
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error) validateInput(e.target.value);
    },
    [validateInput, error]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!apiLink) {
        return;
      }

      if (!apiParam) {
        window.open(apiLink, '_blank');
        return;
      }

      const formData = new FormData(e.target as HTMLFormElement);

      if (!formData.has(inputName)) {
        console.error(`No input with name "${inputName}" in form`);
        return;
      }

      const inputValue = formData.get(inputName) as string;

      const input = validateInput(inputValue);

      if (!input) {
        return;
      }

      const url = new URL(apiLink);
      const [key] = apiParam;

      if (!input) {
        url.searchParams.delete(key);
        window.open(url.toString(), '_blank');
        return;
      }

      url.searchParams.set(key, input);
      window.open(url.toString(), '_blank');
    },
    [apiLink, apiParam, validateInput, inputName]
  );

  return {
    inputName,
    error,
    validateInput,
    handleOnChange,
    handleOnBlur,
    onSubmit,
  };
};
