'use client';

import { type DefaultTheme, styled } from 'styled-components';
import {
  InputPlaceholderText,
  InputText,
} from '@/lib/StyledComponents/Typography';
import { type TextBaseStyleProps } from '@/lib/StyledComponents/styled';

type InputProps = {
  $uppercase?: boolean;
  $fullWidth?: boolean;
  $textAlign?: 'left' | 'center' | 'right';
  $borderColor?: keyof DefaultTheme['colors']['main'];
} & TextBaseStyleProps;

export const Input = styled.input<InputProps>`
  width: ${({ $fullWidth: fullWidth }) => (fullWidth ? '100%' : 'initial')};
  box-sizing: border-box;
  margin: 0;
  padding: clamp(0.75rem, 2vw, 1.125rem) clamp(1.5rem, 2vw, 1.875rem);

  border: 0.125rem solid
    ${({ theme, $borderColor: borderColor }) =>
      borderColor ? theme.colors.main[borderColor] : 'white'};
  border-radius: ${({ theme }) => `${theme.buttonRadius}rem`};

  text-transform: ${({ $uppercase: uppercase }) =>
    uppercase ? 'uppercase' : 'none'};
  text-align: ${({ $textAlign: textAlign = 'center' }) => textAlign};
  color: ${({ theme, $textColor: color }) =>
    color ? theme.colors.text[color] : theme.colors.text['dark']};
  background-color: ${({ theme, $borderColor: borderColor }) =>
    borderColor ? theme.colors.main[borderColor] : 'white'};

  &::placeholder {
    ${InputPlaceholderText}
  }

  &:focus {
    outline: none;
  }

  ${InputText};
`;
