'use client';

import { styled } from 'styled-components';
import type { ArticleCategoryModel } from '@/schema/cms/models/ArticlesPageModel.schema';
import { mq } from '@/lib/StyledComponents/mq';
import type { MouseEventHandler } from 'react';

export type ChipProps = {
  $backgroundColor: ArticleCategoryModel['color'];
  $isClickable: boolean;
  $isSelected: boolean;
};

const Chip = styled.span<ChipProps>`
  background-color: ${({ theme, $backgroundColor }) =>
    `${theme.colors.main[$backgroundColor]}`};
  padding: 0.25rem 1.125rem;
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1.125rem;
  border-radius: 100px;
  ${({ $isSelected }) => $isSelected && 'text-decoration: underline;'}

  ${mq('laptop')} {
    font-size: 1rem;
    padding: 0.375rem 1.5rem;
    line-height: 1.5rem;
  }

  &:hover {
    ${({ $isClickable }) => $isClickable && 'cursor: pointer;'}
  }
`;

type ArticleCategoryChipProps = {
  label: string;
  color: ArticleCategoryModel['color'];
  onClick?: MouseEventHandler;
  isSelected?: boolean;
};

export const ArticleCategoryChip = ({
  label,
  color,
  onClick,
  isSelected = false,
}: ArticleCategoryChipProps) => {
  const isClickable = Boolean(onClick);

  return (
    <Chip
      onClick={onClick}
      $backgroundColor={color}
      $isClickable={isClickable}
      $isSelected={isSelected}
    >
      {label}
    </Chip>
  );
};
