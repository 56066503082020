'use client';

import { mq } from '@/lib/StyledComponents/mq';
import React from 'react';
import { Image as CmsImage, type ResponsiveImageType } from 'react-datocms';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: 17rem;

  ${mq('tablet')} {
    height: 32rem;
  }

  ${mq('laptop')} {
    height: 50rem;
  }
`;

const BasicMedia = styled.div`
  height: 100%;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) =>
      `linear-gradient(0deg, ${theme.colors.background.dark1} 0, transparent)`};
  }
`;

type Props = {
  responsiveImage: ResponsiveImageType;
};

const BackgroundImage = ({ responsiveImage }: Props) => {
  return (
    <Container>
      <BasicMedia>
        <CmsImage
          data={responsiveImage}
          objectFit="cover"
          style={{ position: 'static', height: '100%' }}
          placeholderStyle={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        />
      </BasicMedia>
    </Container>
  );
};

export default BackgroundImage;
